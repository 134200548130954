import {Component} from "solid-js";

type Props = {}

const Footer: Component<Props> = (props) => {
    return <div id="footer">
        <div class="container">
            <p>S. Jansen © 2023 – 2024. All rights reserved.</p>
        </div>
    </div>
}

export default Footer;