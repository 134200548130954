import * as Sentry from "@sentry/browser";
import {backendHost} from "./api";

Sentry.init({
    dsn: "https://70154bb5c7e27f9a4708b908d7407fd6@o4506942264573952.ingest.us.sentry.io/4506942275977216",
    tunnel: `${backendHost}/api/v1/sentry`,
    debug: process.env.NODE_ENV === "development",
    environment: process.env.NODE_ENV,
    autoSessionTracking: true,
    enabled: process.env.NODE_ENV !== "development",

    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],

    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/hymnbook\.sajansen\.nl\//],

    // Session Replay
    replaysSessionSampleRate: process.env.NODE_ENV === "development" ? 1 : 0.3, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
