export function toReadableEnum(text: string): string {
    return text.replace(/([A-Z])/g, " $1").toLowerCase().trim();
}

export function capitalize(word: string) {
    if (word.length === 0) {
        return word;
    }

    if (word.length === 1) {
        return word.toUpperCase();
    }

    return word.charAt(0).toUpperCase() + word.substring(1);
}

export const printPercentage = (value: number, maxValue: number, precision: number = 1) => {
    if (maxValue == 0) {
        return '?'
    }
    const percentage = value / (maxValue) * 100;
    return percentage.toFixed(percentage > 0 && percentage < 1 ? precision + 1 : precision) + " %"
}

export const getClientOs = (): "android" | "windows" | "ios" | "unknown" => {
    const userAgent = navigator.userAgent || "";
    if (/android/i.test(userAgent)) return "android";
    if (/windows phone/i.test(userAgent)) return "windows";
    if (/iPad|iPhone|iPod/i.test(userAgent)) return "ios";
    return "unknown";
}