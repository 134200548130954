import {Component} from "solid-js";
import imageGooglePlay from "../../assets/images/google_play_store.svg";
import imageAppleStore from "../../assets/images/apple_store.svg";
import imageGitHub from "../../assets/images/github.svg";
import './badges.less';

type BaseProps = {
    size?: "normal" | "small";
    url: string
    image: string
    title: string
    alt: string
}

export const BaseBadge: Component<BaseProps> = (props) =>
    <a href={props.url}
       target="_blank"
       class={`store-badge ${props.size ?? "normal"}`}
       title={props.title}>
        <img src={props.image} alt={props.alt}/>
    </a>

type Props = {
    size?: "normal" | "small";
}

export const GooglePlayBadge: Component<Props> = (props) =>
    <BaseBadge
        url="https://play.google.com/store/apps/details?id=nl.sajansen.hymnbook2"
        title="Download from Google Play"
        image={imageGooglePlay}
        alt="Google Play"
        size={props.size}/>

export const AppleStoreBadge: Component<Props> = (props) =>
    <BaseBadge
        url="https://apps.apple.com/app/hymnbook/id1641070543"
        title="Download from App Store"
        image={imageAppleStore}
        alt="App Store"
        size={props.size}/>

export const GitHubBadge: Component<Props> = (props) =>
    <BaseBadge
        url="https://github.com/sampie777/hymnbook2/releases/latest"
        title="Download the APK from GitHub"
        image={imageGitHub}
        alt="GitHub"
        size={props.size}/>