import type {Component} from 'solid-js';

import {Route, Router, Routes} from "@solidjs/router";
import './gui/styles.less';
import {routes} from "./logic/routes";
import Footer from "./gui/components/Footer";
import {ErrorBoundary} from "solid-js";
import ErrorBoundaryFallback from "./gui/components/ErrorBoundaryFallback";

const App: Component = () => {
    return <>
        <div id={"main"}>
            <ErrorBoundary fallback={(err, reset) => <ErrorBoundaryFallback error={err} reset={reset}/>}>
                <Router>
                    <Routes>
                        <>
                            {Object.values(routes).map(it =>
                                <Route path={it.path} component={it.component}/>
                            )}
                        </>
                    </Routes>
                </Router>
            </ErrorBoundary>
        </div>
        <Footer/>
    </>
};

export default App;
