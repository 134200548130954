import {Component, lazy} from "solid-js";
import HomePage from "../gui/homepage/HomePage";

const LogInScreen = lazy(() => import("../gui/login/LogInScreen"));
const FeaturesScreen = lazy(() => import("../gui/feedback/features/FeaturesScreen"));
const BackendFeaturesScreen = lazy(() => import("../gui/feedback/backend/FeaturesScreen"));
const FeaturesAddScreen = lazy(() => import("../gui/feedback/backend/new/FeaturesAddScreen"));
const FeaturesEditScreen = lazy(() => import("../gui/feedback/backend/edit/FeaturesEditScreen"));
const BackendScreen = lazy(() => import("../gui/backend/BackendScreen"));
const FeatureTogglesScreen = lazy(() => import("../gui/backend/featuretoggles/FeatureTogglesScreen"));
const LiedboekScreen = lazy(() => import("../gui/backend/liedboek/LiedboekScreen"));
const SettingsLogScreen = lazy(() => import("../gui/backend/settingslog/SettingsLogScreen"));
const ClientsScreen = lazy(() => import("../gui/backend/clients/ClientsScreen"));
const ClientsLabelNewScreen = lazy(() => import("../gui/backend/clients/label/NewForm"));
const ClientsLabelEditScreen = lazy(() => import("../gui/backend/clients/label/EditForm"));
const PrivacyPolicyScreen = lazy(() => import("../gui/privacy/PrivacyPolicyScreen"));

type Route = {
    path: string;
    component: Component;
}

// Note that every path starting with /backend will require the user to be admin privileged
export const routes = {
    Home: {
        path: "/",
        component: HomePage,
    },
    LogIn: {
        path: "/login",
        component: LogInScreen,
    },
    PrivacyPolicy: {
        path: "/privacy",
        component: PrivacyPolicyScreen,
    },
    Liedboek: {
        path: "/liedboek",
        component: LiedboekScreen,
    },
    FeedbackFeatures: {
        path: "/feedback/features/:user?",
        component: FeaturesScreen,
    },

    Backend: {
        path: "/backend",
        component: BackendScreen,
    },
    FeatureToggles: {
        path: "/backend/features",
        component: FeatureTogglesScreen,
    },
    SettingsLog: {
        path: "/backend/settingslog",
        component: SettingsLogScreen,
    },
    BackendFeedbackFeatures: {
        path: "/backend/feedback/features",
        component: BackendFeaturesScreen,
    },
    BackendFeedbackFeaturesAdd: {
        path: "/backend/feedback/features/new",
        component: FeaturesAddScreen,
    },
    BackendFeedbackFeaturesEdit: {
        path: "/backend/feedback/features/:id",
        component: FeaturesEditScreen,
    },
    BackendClients: {
        path: "/backend/clients",
        component: ClientsScreen,
    },
    BackendClientsLabelNew: {
        path: "/backend/clients/labels/new/:client",
        component: ClientsLabelNewScreen,
    },
    BackendClientsLabelEdit: {
        path: "/backend/clients/labels/edit/:client",
        component: ClientsLabelEditScreen,
    },
};

export const routesGeneratePath = (route: Route, args: { [key: string]: any }) => {
    let path = route.path;
    Object.entries(args).forEach(([key, value]) => {
        path = path.replace(RegExp(`/:${key}\\??(/|$)`, "gi"), `/${value ?? ""}$1`);
    })
    return path;
}