import {Models as FeatureRequestsModels} from "../../../src/featurerequests/models";
import {Models as FeatureTogglesModels} from "../../../src/featuretoggles/models";
import {Models as ClientsModels} from "../../../src/clients/models";
import process from "process";

export const backendHost = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "";
const endpoint = `${backendHost}/api/v1`;

// For debug purposes
const delay = <T>(func: () => Promise<T>): Promise<T> => {
    if (process.env.NODE_ENV === "development")
        return new Promise(resolve => setTimeout(() => resolve(func() as T), 1000))
    return func()
}

const devHeaders: RequestInit = process.env.NODE_ENV === "production" ? {} : {
    credentials: "include",
    mode: "cors",
};

const get = (url: string) => {
    return delay(() => fetch(url, devHeaders));
}

const post = (url: string, body: any = undefined) => delay(() => fetch(url, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: body !== undefined ? JSON.stringify(body) : undefined,
        ...devHeaders
    })
)

const put = (url: string, body: any = "") => delay(() => fetch(url, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
        ...devHeaders
    })
)

const remove = (url: string) => delay(() => fetch(url, {
        method: "DELETE",
        ...devHeaders
    })
)

export const api = {
    feedback: {
        features: {
            me: () => get(`${endpoint}/feedback/features/me`),
            register: (user: string) => get(`${endpoint}/feedback/features/register?user=${user}`),
            all: () => get(`${endpoint}/feedback/features`),
            single: (item: { id: number }) => get(`${endpoint}/feedback/features/${item.id}`),
            create: (item: FeatureRequestsModels.Item) => post(`${endpoint}/feedback/features`, item),
            update: (item: FeatureRequestsModels.Item) => put(`${endpoint}/feedback/features/${item.id}`, item),
            delete: (item: FeatureRequestsModels.Item) => remove(`${endpoint}/feedback/features/${item.id}`),

            vote: (vote: FeatureRequestsModels.Vote) => post(`${endpoint}/feedback/features/votes`, vote),
            unvote: (item: { id: number }, user: string) =>
                remove(`${endpoint}/feedback/features/votes?itemId=${item.id}&user=${user}`),

            comments: {
                create: (comment: FeatureRequestsModels.Comment) => post(`${endpoint}/feedback/features/comments`, comment),
                update: (comment: FeatureRequestsModels.Comment) => put(`${endpoint}/feedback/features/comments/${comment.id}`, comment),
                delete: (comment: FeatureRequestsModels.Comment) => remove(`${endpoint}/feedback/features/comments/${comment.id}`),
            }
        }
    },
    settings: {
        all: () => get(`${endpoint}/data/settings`),
    },
    featuretoggles: {
        all: () => get(`${endpoint}/features/all`),
        create: (obj: FeatureTogglesModels.FeatureToggleTarget) => post(`${endpoint}/features`, obj),
        update: (obj: FeatureTogglesModels.FeatureToggleTarget) => put(`${endpoint}/features/${obj.id}`, obj),
        delete: (obj: FeatureTogglesModels.FeatureToggleTarget) => remove(`${endpoint}/features/${obj.id}`),
    },
    clients: {
        all: (offset = 0, limit = 10) => get(`${endpoint}/clients`
            + `?offset=${offset}`
            + `&limit=${limit}`),
        create: (obj: ClientsModels.Client) => post(`${endpoint}/clients`, obj),
        update: (obj: ClientsModels.Client) => put(`${endpoint}/clients/${obj.id}`, obj),
        delete: (obj: ClientsModels.Client) => remove(`${endpoint}/clients/${obj.id}`),
        markAsRobot: (obj: {
            clientID: string
        }, isRobot: boolean) => post(`${endpoint}/clients/${obj.clientID}/robot?isRobot=${isRobot}`),
        geolocation: (obj: {ip: string}) => get(`${endpoint}/clients/geolocation/${obj.ip}`),

        labels: {
            all: () => get(`${endpoint}/clients/labels`),
            single: (obj: { clientID: string }) => get(`${endpoint}/clients/labels/${obj.clientID}`),
            create: (obj: ClientsModels.ClientLabel) => post(`${endpoint}/clients/labels`, obj),
            update: (obj: ClientsModels.ClientLabel) => put(`${endpoint}/clients/labels/${obj.id}`, obj),
            delete: (obj: ClientsModels.ClientLabel) => remove(`${endpoint}/clients/labels/${obj.id}`),
        }
    }
}