import type {Component} from 'solid-js';
import imagePaypal from "/src/assets/images/paypal-logo.png";
import './styles.less';
import {getClientOs} from "../utils";
import {AppleStoreBadge, GitHubBadge, GooglePlayBadge} from "../components/badges";

const HomePage: Component = () => {
    const getBadgeForClientOs = () => {
        const clientOs = getClientOs();
        switch (clientOs) {
            case "android":
                return <GooglePlayBadge/>
            case "ios":
                return <AppleStoreBadge/>
            case "windows":
            case "unknown":
                return null
        }
    }

    const badgeForClientOs = getBadgeForClientOs();

    return <>
        <div id="hero">
            <div class="container">
                <ul id="hero-points">
                    <li>Fast.</li>
                    <li>Elegant.</li>
                    <li>Simple.</li>
                </ul>
            </div>
        </div>

        {badgeForClientOs == null ? null :
            <div class="container" id="quick-download">
                {badgeForClientOs}
                <small>Scroll down for more options</small>
            </div>
        }

        <div id="intro">
            <div class="container">
                <h1 id="app-title">Hymnbook</h1>
                <p>Welcome to Hymnbook, the mobile app that brings you Christian song lyrics in a modern and
                    user-friendly
                    format. Download the app today and start singing your favorite hymns.</p>

                <div id="scripture">
                    <p>"Als de HERE de bouw van een huis niet zegent, is alle moeite nutteloos."</p>
                    <p class="scripture-source">Psalmen 127: 1a (HTB)</p>
                </div>

                <p>With Hymnbook, you can access a vast collection of Christian song lyrics from your mobile device.
                    Whether
                    you're at home, at church, or on the go, Hymnbook makes it easy to sing along and worship in spirit
                    and
                    truth.</p>
            </div>
        </div>

        <div id="features">
            <div class="container">
                <h2>Features</h2>
                <p>Hymnbook offers a range of features to enhance your experience with Christian music. With our
                    easy-to-use
                    search function, you can find specific songs with minimal effort. Don't know the melody? View the
                    notes or let the app play it for you. Download the songs you want to sing and
                    explore many more.</p>
                <br/>
                <p>Some of the main and most used functionalities are:</p>
                <ul>
                    <li><strong>Fastest</strong> and most convenient song selection</li>
                    <li>Quickly create a song list</li>
                    <li>Play and view the <strong>melody</strong></li>
                    <li><strong>Search</strong> through the lyrics</li>
                </ul>
                <br/>
            </div>
        </div>

        <div id="download">
            <div class="container" id="downloads">
                <h2>Download</h2>
                <p>Hymnbook is available on both Android and iOS. Download the app today and start singing your heart
                    out.</p>

                <div class="store-badges-container">
                    <GooglePlayBadge/>
                    <AppleStoreBadge/>
                </div>

                <div style={{"text-align": "center"}}>
                    <p>Don't have Android or iOS? Download the app bundle directly from the source on GitHub.</p>
                    <GitHubBadge size={"small"}/>
                </div>
            </div>
        </div>

        <div id="donate">
            <div class="container">
                <h2>Donate</h2>
                <p>This app is made free in order to make the access to Christian songs available for everyone with a
                    digital
                    device. As no profit is made, this app fully depend on donations. If you want to contribute or show
                    your
                    thanks, please consider donating using one of the following options:
                </p>

                <div id="donate-links">
                    <a href="https://www.buymeacoffee.com/sajansen"
                       target="_blank"
                       class="donation-link">
                    <span class="spacing">
                        <img class="donate-logo buymeacoffe"
                             src="https://cdn.buymeacoffee.com/uploads/profile_pictures/default/5F7FFF/SJ.png"/>
                    </span>
                        <span class="text">Buy me a coffee</span>
                        <span class="spacing"></span>
                    </a>

                    <a href="https://www.paypal.com/donate/?hosted_button_id=6KTU5JNVS699E"
                       target="_blank"
                       class="donation-link">
                    <span class="spacing">
                        <img class="donate-logo paypal" src={imagePaypal}/>
                    </span>
                        <span class="text">Donate using PayPal</span>
                        <span class="spacing"></span>
                    </a>
                </div>
            </div>
        </div>
    </>
};

export default HomePage;
